<template>
  <v-sheet color="#001a4d" style="overflow:hidden !important" tile class="footer apply-background-color pa-0 px-2 mx-0" fluid height="100%">
  <v-container class="confirm-password center-flex-items" fluid style="height: 100%">
    <v-img v-if="statusCode === 11 || statusCode === 10" src="img/syfol-login-bg.png" lazy-src="img/syfol-login-bg.png" height="100vh" width="100%">
    </v-img>
    <v-card v-else  class="ma-auto px-9 py-3" elevation="5" rounded="lg">
      <v-card-title class="mb-5 justify-center">
        <img src="/img/syfol/logo.png" alt="Logo" height="35px" class="mr-5">
        <span class="text-header">{{ appTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field prepend-inner-icon="mdi-account" label="Username" type="text" v-model="resetObj.username" disabled outlined dense></v-text-field>
          <v-text-field outlined dense prepend-inner-icon="mdi-lock" :append-icon="showPasswordPrimary ? 'mdi-eye' : 'mdi-eye-off'" v-model="resetObj.password"
            :type="showPasswordPrimary ? 'text' : 'password'" label="Password" @click:append="showPasswordPrimary=!showPasswordPrimary" :rules="$_requiredValidation">
          </v-text-field>
          <v-text-field outlined dense prepend-inner-icon="mdi-lock" :append-icon="showPasswordSecondary ? 'mdi-eye' : 'mdi-eye-off'" v-model="resetObj.confirmpassword"
            :type="showPasswordSecondary ? 'text' : 'password'" label="Confirm Password" @click:append="showPasswordSecondary=!showPasswordSecondary"
            :rules="resetObj.confirmpassword ? $_passwordConfirmationRules : $_requiredValidation">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="center-flex-items px-5">
        <v-btn :loading="loading" :disabled="loading" @click="$route.query.domain ? savePassword() : $route.query.isuserlogin ? createUserLicense() : savePassword()" block large color="success">Confirm</v-btn>
      </v-card-actions>
      <!-- <v-flex lg12 md12 sm12 xs12>
        <div class="text-center font-weight-medium my-2 caption"> (or) </div>
      </v-flex>
      <v-card-actions class="center-flex-items px-5">
        <v-flex lg12 md12 sm12 xs12>
          <div>
            <v-btn block color="primary darken-2" @click="getSharepointUrl" :loading="loading" height="40" :disabled="normalUserLoader">
              <v-icon small class="mx-3"> mdi-microsoft </v-icon>
              <span class="body-2 mt-1 font-weight-medium"> Login with Microsoft </span>
            </v-btn>
          </div>
        </v-flex>
      </v-card-actions> -->
    </v-card>
    <v-dialog v-model="dialog" persistent width="400">
      <v-card>
        <v-card-title>
          Message
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <span class="font-weight-medium">Password updated successfully. Please try login </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" @click="$router.push('/login')">Ok</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="statusCode === 11" v-model="passwordSetAlready" width="400" persistent>
      <v-card>
        <v-card-title style="background-color:#1565C0 !important" class="white--text pa-4 pb-3">
          <v-flex class="d-flex align-center">
            <v-icon dark size="20"> mdi-login-variant </v-icon>
            <span class="px-2 body-1 font-weight-medium"> Please do login </span>
          </v-flex>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-flex class="text-center">
            <span class="subtitle-1 font-weight-bold"> Password set already please do login  </span>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" small @click="snackbar = false;$router.push('/login')">
              Login
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="statusCode === 10" v-model="isResendPassword" width="400" persistent>
      <v-card>
        <v-card-title style="background-color:#1565C0 !important" class="white--text pa-4 pb-3">
          <v-flex class="d-flex align-center">
            <v-icon dark size="20"> mdi-alert-outline </v-icon>
              <span class="px-2 body-1 font-weight-medium"> Password reset timeout </span>
          </v-flex>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-flex class="text-center">
            <span class="subtitle-1 font-weight-bold"> Your link has expired. Please request new link to proceed.  </span>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-flex class="text-center">
            <!-- <v-btn color="primary" small @click="resendPassword()">
              Send Request
            </v-btn> -->
            <v-btn color="primary" small @click="$router.push('/forgotpassword')">
              Send Request
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</v-sheet>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      valid: true,
      resetObj: {},
      snackbarAuth: false,
      text: 'text',
      color: 'success',
      dialog: false,
      showPasswordPrimary: false,
      showPasswordSecondary: false,
      loginModel: {},
      statusCode: null,
      dialogForPasswordSet: false,
      passwordSetAlready: false,
      isResendPassword: false,
      normalUserLoader: false
    }
  },
  created () {
    this.$store.dispatch('logout')
    this.$i18n.locale = 'no'
    this.getUserDetailsByToken()
    this.resetObj.token = this.$route.query.token
  },
  computed: {
    appTitle () {
      return 'SYFol'
    }
  },
  mounted () {
    if (this.$route.query.code) {
      window.localStorage.setItem(process.env.VUE_APP_SHAREPOINTAUTHORIZATIONCODE, this.$route.query.code)
      const model = { code: this.$route.query.code }
      this.$router.replace({ path: this.$route.path, query: {} })
      this.$store.commit('startRouteLoader')
      this.loginActions(model)
      JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_theme_color`))
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_theme_color`))
    }
  },
  methods: {
    getUserDetailsByToken () {
      this.$store.dispatch('logout')
      if (this.$route.query.domain) {
        this.$api.execute('get', `users/get_by_otp?domain=${this.$route.query.domain}&otp=${this.$route.query.otp}`).then((response) => {
          if (response.data && !response.data.status) {
            this.resetObj.username = response.data.email
            this.resetObj.email = response.data.email
            this.resetObj.domain = this.$route.query.domain
            this.$forceUpdate()
          } else {
            if (response.data.status === 11) this.passwordSetAlready = true
            if (response.data.status === 10) this.isResendPassword = true
            this.statusCode = response.data.status
          }
        }).catch((error) => {
          console.log(error.message)
        })
      } else {
        this.$api.execute('post', 'auth/verify_user_otp', { otp: this.$route.query.otp }).then((response) => {
          if (response && response.data.status) {
            if (response.data.status === 11) this.passwordSetAlready = true
            if (response.data.status === 10) this.isResendPassword = true
            this.statusCode = response.data.status
          } else {
            if (response && !response.data.status) {
              this.resetObj.username = response.data.email
              this.resetObj.domain = response.customerdomain
              this.$forceUpdate()
            }
            if (this.$route.query.otp != null && this.$route.query.isuserlogin) {
              this.resetObj.username = response.data.email
              this.resetObj.email = response.data.email
            }
          }
        })
      }
    },
    savePassword () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const url = this.$route.query.domain ? 'users/reset_password' : 'auth/reset_password'
        if (this.$route.query.domain) this.resetObj.token = this.$route.query.otp
        this.resetObj.ispasswordset = true
        this.$api.execute('post', url, this.resetObj)
          .then((response) => {
            if (response) {
              this.dialog = true
              setTimeout(() => {
                this.$router.push('/login')
              }, 1500)
            }
          })
          /* eslint-disable */
          .catch((error) => {
            this.loading = false
            this.$store.commit('logout')
          })
      }
    },
    createUserLicense () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.resetObj.password = this.resetObj.confirmpassword
        this.$api.execute('post', 'auth/userlicense_for_registered_domain', this.resetObj)
          .then((response) => {
            if (response) {
              this.dialog = true
              setTimeout(() => {
                this.$router.push('/login')
              }, 1500)
            }
          })
          /* eslint-disable */
          .catch((error) => {
            this.loading = false
            this.$store.commit('logout')
          })
      } else {
        return false
      }
    },
     getSharepointUrl () {
      this.loading = true
      this.$api.execute('get', 'auth/get_sharepoint_auth_url').then(async response => {
        if (response.data) {
          window.open(response.data.url, '_self')
        }
      }).finally(() => {
        this.loading = false
      })
    },
    loginActions (model, isNormalLogin) {
      if (!isNormalLogin) this.loading = true
      else this.normalUserLoader = true
      model.isSignupSharepoint = (window.localStorage.getItem(`${process.env.VUE_APP_NAME}_isLoginSharepoint`) && window.localStorage.getItem(`${process.env.VUE_APP_NAME}_isLoginSharepoint`)) === 'false'
      this.$store.dispatch('login', model)
        .then((result) => {
          this.loggedInInfo = result
          if (result.statuscode) {
            switch (result.statuscode) {
              case 1:
                let customerObj = JSON.parse(localStorage.getItem('syfol_customersetupobj'))
                customerObj = Object.assign({}, { ...this.customerObj,
                  email: customerObj.username,
                  username: customerObj.username,
                  contact: customerObj.name,
                  customerdomain: customerObj.customerdomain,
                  authtoken: customerObj.authtoken,
                  company: customerObj.customerdomain
                })
                this.$store.state.common.customerObj = customerObj

                this.customerObj = this.$store.state.common.customerObj
                this.createDomainAndWorkspace()
                // this.$router.push(`/organizationdetails?code=${result.authtoken}&domain=${result.customerdomain}`)
                break
              case 2 :
                this.$store.commit('stopRouteLoader')
                this.$router.push('/tenantsavailable')
                break
              case 3 :
                this.$store.commit('stopRouteLoader')
                this.$store.commit('multipleTenants', result)
                this.$router.push('/tenantsavailable')
                break
              case 4 :
                this.$store.commit('stopRouteLoader')
                this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'Domain registered already try login', isException: true })
                setTimeout(() => {
                  this.$router.push('/login')
                }, 1000)
            }
          } else {
            // this.$store.state.common.loadRouter = true
            /* get sharepoint settings */
            if (this.loggedInInfo.issharepointstorage) {
              this.$api.execute('get', 'sharepointsetting').then((response) => {
                const sharepointSetting = response.data
                this.$root.$emit('setSignalrAndNotification')
                this.$root.$emit('notificationUnreadCount')
                this.$store.dispatch('fetchIntegrations')
                this.$store.dispatch('getUserGroups').then(() => {
                  this.$store.dispatch('getModuleList').then(() => {
                    this.$store.dispatch('getUsers').then(() => {
                      this.$store.dispatch('getAppLanguages')
                      if (sharepointSetting.siteid && sharepointSetting.list_id) {
                        this.$store.dispatch('getSharepointFields')
                      }
                      this.$store.commit('startRouteLoader')
                      this.$store.state.common.loadRouter = false
                      if (!result.sharepointconfigdone && result.issharepointuser && result.isdomainadmin && !this.loggedInInfo.landingpageafterlogin) {
                        this.$router.push('/sharepoint')
                      } else if (!this.loggedInInfo.landingpageafterlogin) { this.$router.push('/dashboard') }
                      this.getWorkspacesOfCurrentUser()
                    })
                  })
                })
                this.$store.dispatch('systemNotification').then((response) => {
                  this.$root.$emit('getSystemNotificationFromTenant', response)
                })
              })
            } else {
              this.$root.$emit('setSignalrAndNotification')
              this.$root.$emit('notificationUnreadCount')
              this.$store.dispatch('fetchIntegrations')
              this.$store.dispatch('getUserGroups').then(() => {
                this.$store.dispatch('getModuleList').then(() => {
                  this.$store.dispatch('getUsers').then(() => {
                    this.$store.dispatch('getAppLanguages')
                    this.$store.commit('startRouteLoader')
                    this.$store.state.common.loadRouter = false
                    if (this.loggedInInfo.isdomainadmin && !this.loggedInInfo.landingpageafterlogin) {
                      // this.$router.push('/foldersync')
                    } else if (!this.loggedInInfo.landingpageafterlogin) { this.$router.push('/dashboard') }
                    this.getWorkspacesOfCurrentUser()
                  })
                })
              })
              this.$store.dispatch('systemNotification').then((response) => {
                this.$root.$emit('getSystemNotificationFromTenant', response)
              })
            }
          }
          if (this.loggedInInfo.landingpageafterlogin) {
            this.$router.push(this.loggedInInfo.landingpageafterlogin)
          }
        })
        .catch(() => {
          this.$store.state.common.loadRouter = false
        })
        .finally(() => {
          if (!isNormalLogin) this.loading = false
          else this.normalUserLoader = false
        })
    },
    resendPassword () {
      /** need to implement in future */
    }
  }
}
</script>
<style>
.carousel {
  height: 200px !important;
}
.confirm-password {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.center-flex-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-header {
  font-size: 28px;
  font-weight: bolder;
  color: #124c75;
}
</style>